import React from 'react'

function Navbar() {
    return (
        <div className='container-fluid' id='nav-root'>
            <nav class="navbar navbar-expand-md navbar-light">

                <a class="navbar-brand" href="#">Grayps</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar1">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbar1">
                    <ul class="navbar-nav">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Docs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Community</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Blog</a>
                        </li>
                    </ul>
                </div>

            </nav>
        </div>
    )
}

export default Navbar