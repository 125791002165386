import React from 'react'

import '../Header.css'
function Header() {
    return (
        <div className='header-root'>
        <div className='container'>
            <header>
         <div className='row'>
         <div className="col align-self-center">
         <img src='./grayps.png'  className="rounded mx-auto d-block"></img>
         </div>
         </div>
         <div className='row'>
            <div className='col align-self-center text-center'>
                <h1>Graypsssss</h1>
                </div>
           </div>
         </header>
        </div>
        </div>
    )
}

export default Header