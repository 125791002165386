import logo from './logo.svg';
import Header from './components/Header';
import Navbar from './components/Navbar';
import './Navbar.css'
function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Header></Header>
    </div>
  );
}

export default App;
